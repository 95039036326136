import classNames from 'classnames';
import React from 'react';

export function ButtonGroup({
  className,
  size,
  ...props
}: {
  size?: 'sm';
} & React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={classNames(
        'flex flex-wrap',
        { 'gap-4': !size, 'gap-2': size === 'sm' },
        className
      )}
      {...props}
    />
  );
}
