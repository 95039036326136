import React from 'react';
import Image, { ImageProps } from 'next/image';
import {
  useNextSanityImage,
  UseNextSanityImageOptions,
} from 'next-sanity-image';
import { sanityClient } from '@lib/sanity';
import type { SanityImageSource } from '@sanity/image-url/lib/types/types';

interface NextSanityImageProps
  extends Omit<ImageProps, 'width' | 'height' | 'src' | 'loader'> {
  image: SanityImageSource;
  options?: UseNextSanityImageOptions & {
    enableBlurUp?: boolean;
  };
}

export function NextSanityImage({
  image,
  options,
  layout = 'responsive',
  ...props
}: NextSanityImageProps) {
  const nextSanityImage = useNextSanityImage(
    sanityClient,
    image,
    options
  );
  if(!nextSanityImage){
    return null;
  }
  const { placeholder, ...imageProps } = nextSanityImage
  return (
    <Image
      layout={layout}
      placeholder={placeholder as 'blur' | 'empty' | undefined}
      {...props}
      {...imageProps}
    />
  );
}
