import { PortableText } from '@components/sanity/PortableText';
import { Button } from '@components/ui/Button';
import { ButtonGroup } from '@components/ui/ButtonGroup';
import { useAnalyticsConsent } from '@lib/analytics-consent';
import { useMounted } from '@lib/hooks/useMounted';
import { sanitySettings } from '@lib/sanity-settings';
import React from 'react';

export default function CookieNotifier() {
  const { actionRequired, setConsent } = useAnalyticsConsent();
  const mounted = useMounted();
  if (!mounted || !actionRequired || !sanitySettings.analyticsConsent?.body) {
    return null;
  }
  return (
    <div
      data-testid="CookieNotifier"
      className="fixed bottom-0 z-40 flex flex-wrap items-center w-full gap-2 px-4 py-3 bg-white border-t border-gray-300"
    >
      <PortableText blocks={sanitySettings.analyticsConsent.body} />
      <ButtonGroup>
        <Button
          type="button"
          size="sm"
          onClick={() => {
            setConsent('false');
          }}
        >
          Reject
        </Button>
        <Button
          type="button"
          size="sm"
          onClick={() => {
            setConsent('true');
          }}
          variant="primaryGreen"
        >
          Accept
        </Button>
      </ButtonGroup>
    </div>
  );
}
