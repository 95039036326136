import React from 'react';
import classNames from 'classnames';

export function Heading({
  level = 'h1',
  size = 'md',
  children,
  className,
}: {
  level: 'h1' | 'h2' | 'h3';
  size?: 'xl' | 'lg' | 'md' | 'sm';
  className?: string;
  children: React.ReactNode;
}) {
  const headingClassName = classNames(className, {
    'text-lg font-serif': size === 'md',
    'text-2xl font-serif': size === 'lg',
    'text-4xl font-serif': size === 'xl',
  });
  switch (level) {
    case 'h1': {
      return <h1 className={headingClassName}>{children}</h1>;
    }
    case 'h2': {
      return <h2 className={headingClassName}>{children}</h2>;
    }
    case 'h3': {
      return <h3 className={headingClassName}>{children}</h3>;
    }
  }
}
