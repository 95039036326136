import { Pathname } from '@components/common/routing';
import { EditionsLocation } from '@generated/codegen';

export function editionsLocationToPathname(
  location: EditionsLocation | undefined
): Pathname {
  switch (location) {
    case EditionsLocation.Vika: {
      return '/vika';
    }
    case EditionsLocation.Editions_14: {
      return '/editions-14';
    }
    case EditionsLocation.Editions_15: {
      return '/editions-15';
    }
    case EditionsLocation.Kalender: {
      return '/kalender';
    }
    case EditionsLocation.Leie: {
      return '/leie';
    }
    case EditionsLocation.Editions_04: {
      return '/editions-04';
    }
    case EditionsLocation.EditionsAamodt: {
      return '/editions-aamodt';
    }
    case EditionsLocation.EditionsPigao: {
      return '/editions-pigao';
    }
    case EditionsLocation.EditionsBarrios: {
      return '/editions-barrios';
    }
    case EditionsLocation.EditionsHvidsten: {
      return '/editions-hvidsten';
    }
    case EditionsLocation.EditionsTerins: {
      return '/editions-terins';
    }
    case EditionsLocation.EditionsGellein: {
      return '/editions-gellein';
    }
    case EditionsLocation.EditionsMikkelsgard: {
      return '/editions-mikkelsgard';
    }
    case EditionsLocation.EditionsDahl: {
      return '/editions-dahl';
    }
    case EditionsLocation.Editions:
    default: {
      return '/editions';
    }
  }
}
