import { useEffect, useState } from 'react';

export function useLockBodyScroll() {
  const [lockBodyScroll, setLockBodyScroll] = useState(false);
  useEffect(() => {
    // Get original body overflow
    const originalStyle = window.getComputedStyle(document.body).overflow;
    // Prevent scrolling on mount
    document.body.style.overflow = lockBodyScroll ? 'hidden' : originalStyle;
    // Re-enable scrolling when component unmounts
    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, [lockBodyScroll]); // Empty array ensures effect is only run on mount and unmount

  return { lockBodyScroll, setLockBodyScroll };
}
