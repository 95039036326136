import React from 'react';
import { useAuthUser, useUserDoc, useUserDocById } from '@lib/authentication';
import classNames from 'classnames';

const twClassNames = [
  'bg-red-600', // emerald
  'bg-yellow-600', // peter river
  'bg-green-600', // wisteria
  'bg-blue-600', // carrot
  'bg-indigo-600', // alizarin
  'bg-purple-600', // turquoise
  'bg-pink-600', // midnight blue
];

function sumChars(str: string) {
  let sum = 0;
  for (let i = 0; i < str.length; i++) {
    sum += str.charCodeAt(i);
  }
  return sum;
}

function getInitials(name: string) {
  const names = name.split(' ');
  let initials = names[0].substring(0, 1).toUpperCase();
  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
}

export function Avatar({
  photoURL,
  loading,
  name = '',
  fullSize,
}: {
  loading: boolean;
  photoURL: string | undefined;
  name: string | undefined;
  fullSize?: boolean;
}) {
  const size = fullSize ? 'h-full w-full' : 'h-8 w-8';
  const fontSize = fullSize ? 'text-2xl' : 'text-sm';
  const imageClassName = `${size} rounded-full`;

  if (photoURL) {
    return <img className={imageClassName} src={photoURL} alt="" />;
  }

  const initials = getInitials(name);

  // pick a deterministic color from the list
  const i = sumChars(name) % twClassNames.length;

  return (
    <div
      className={classNames(
        imageClassName,
        'flex justify-center items-center',
        fontSize,
        { [twClassNames[i]]: !loading },
        { 'animate-pulse bg-gray-400': loading }
      )}
    >
      <span className="font-medium leading-none text-white">{initials}</span>
    </div>
  );
}

export function UserAvatar({
  uid,
  loading,
}: {
  uid: string | undefined;
  loading?: boolean;
}) {
  const [user, userLoading] = useUserDocById(uid);
  return (
    <Avatar
      loading={loading || userLoading}
      photoURL={user ? user.photoUrl : undefined}
      name={user ? user.fullName : undefined}
    />
  );
}

export function CurrentUserAvatar() {
  const { user, loading: userLoading } = useAuthUser();
  const { userDoc, loading: userDocLoading } = useUserDoc();

  let photoURL;
  if (userDoc && userDoc.photoUrl) {
    photoURL = userDoc.photoUrl;
  } else if (user && user.photoURL) {
    photoURL = user.photoURL;
  }

  let name = '';
  if (userDoc && userDoc.fullName) {
    name = userDoc.fullName;
  } else if (user && user.displayName) {
    name = user.displayName;
  }

  return (
    <Avatar
      photoURL={photoURL}
      loading={userLoading || userDocLoading}
      name={name}
    />
  );
}
