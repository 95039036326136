import { object, string, Asserts } from 'yup';

export const newsletterSchema = object({
  email: string()
    .email('Must be a valid email')
    .required('Email is required')
    .max(255)
    .trim(),
});

export type NewsletterSchema = Asserts<typeof newsletterSchema>;
