import { NewsletterSignUpForm } from './newsletterSignUpForm';

export const NewsletterSignUpFooter = () => {
  return (
    <div className="grid grid-cols-12 p-4 md:pt-6 md:pb-6 md:pt-12 md:pb-12 bg-yellow-200">
      <div className="col-span-0 md:col-span-3"></div>
      <div className="col-span-12 md:col-span-6">
        <NewsletterSignUpForm />
      </div>
      <div className="col-span-0 md:col-span-3"></div>
    </div>
  );
};
