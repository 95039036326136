import React from 'react';
import type { SanityFigureNode, SanityPageType } from '@lib/sanity.server';
import BlockContent, {
  BlockContentProps,
} from '@sanity/block-content-to-react';
import { Anchor } from '@components/ui/Anchor';
import { ButtonAnchor, ButtonProps } from '@components/ui/Button';
import Link from 'next/link';
import { urlForImage } from '@lib/sanity';
import { AtelierLink, Pathname } from '../common/routing';
import classNames from 'classnames';
import { NextSanityImage } from './NextSanityImage';

function LinkRenderer({
  children,
  link,
  cta,
}: {
  children: React.ReactNode;
  link?: { blank?: boolean; href?: string };
  cta?: boolean;
}) {
  if (!link) {
    return null;
  }
  const { blank, href } = link;

  const linkProps = {
    href,
    target: blank ? '_blank' : undefined,
    rel: blank ? 'noopener' : undefined,
  };
  const anchorProps = { underline: true, external: blank };

  let anchor = null;

  if (cta) {
    const buttonProps: ButtonProps = {
      variant: 'primaryYellow',
    };
    anchor = (
      <ButtonAnchor {...linkProps} {...buttonProps}>
        {children}
      </ButtonAnchor>
    );
  } else {
    anchor = (
      <Anchor {...linkProps} {...anchorProps}>
        {children}
      </Anchor>
    );
  }
  // todo: match all internal routes here
  if (href === '/my-studio') {
    return <AtelierLink href={href}>{anchor}</AtelierLink>;
  }
  return anchor;
}

function InternalLinkRenderer({
  children,
  internalLink,
  cta,
}: {
  children: React.ReactNode;
  internalLink?: {
    document?: {
      _type: SanityPageType;
      slug?: string;
      route?: string;
    };
  };
  cta?: boolean;
}) {
  if (!internalLink) {
    return null;
  }
  const { document } = internalLink;
  let pathname: Pathname | undefined;
  let slug: string | undefined;
  const query: { slug?: string } = {};
  if (document) {
    switch (document._type) {
      case 'post': {
        if (document.route) {
          pathname = '/i/[slug]';
          slug = document.route;
        }
        break;
      }
      case 'article': {
        if (document.slug) {
          pathname = '/a/[slug]';
          slug = document.route;
        }
        break;
      }
      case 'mapPage': {
        pathname = '/map';
        break;
      }
      case 'termsPage': {
        pathname = '/terms';
        break;
      }
      case 'startPage': {
        pathname = '/';
        break;
      }
      case 'giftCardPage': {
        pathname = '/giftcard';
        break;
      }
    }
  }
  if (!pathname) {
    return null;
  }
  if (slug) {
    query.slug = slug;
  }
  return (
    <Link href={{ pathname, query }} passHref>
      {cta ? (
        <ButtonAnchor variant="primaryYellow">{children}</ButtonAnchor>
      ) : (
        <Anchor underline>{children}</Anchor>
      )}
    </Link>
  );
}

type SanityFigureWidth = 'w-full' | 'w-3/4' | 'w-1/2' | 'w-1/4';

export function SanityFigureRenderer({
  node,
  width,
  className,
}: {
  node?: SanityFigureNode;
  width?: SanityFigureWidth;
  className?: string;
}) {
  if (!node || !node.dimensions) {
    return null;
  }
  const src = urlForImage(node.asset).url();
  if (!src) {
    return null;
  }
  return (
    <figure className={classNames(className, width, 'mx-auto')}>
      <NextSanityImage
        layout="responsive"
        image={node}
        alt={node.alt || ''}
        sizes="(min-width: 768px) 768px, 100vw"
      />
      {node.caption && (
        <figcaption className="mt-2 text-sm text-muted">
          {node.caption}
        </figcaption>
      )}
    </figure>
  );
}

function ArticleFigureRenderer({
  node,
}: {
  node?: {
    _key: string;
    _type: 'articleFigure';
    figure?: SanityFigureNode;
    width?: SanityFigureWidth;
  };
}) {
  if (!node || !node.figure) {
    return null;
  }
  const { width, figure } = node;
  if (!figure.dimensions) {
    return null;
  }
  const src = urlForImage(figure.asset).url();
  if (!src) {
    return null;
  }
  return <SanityFigureRenderer className="mb-4" width={width} node={figure} />;
}

const ListRenderer = (props: {
  children: React.ReactNode;
  level: number;
  type: 'number' | 'bullet';
}) => {
  const twClassNames = 'mb-6 space-y-1 list-inside sm:text-lg leading-snug';
  switch (props.type) {
    case 'number': {
      return (
        <ol className={twClassNames + ' list-decimal'}>{props.children}</ol>
      );
    }
    case 'bullet': {
      return <ul className={twClassNames + ' list-disc'}>{props.children}</ul>;
    }
    default: {
      return null;
    }
  }
};

const serializers = (size?: 'lg'): BlockContentProps['serializers'] => ({
  list: ListRenderer,
  marks: {
    internalLink: ({ mark, children }) => {
      return (
        <InternalLinkRenderer internalLink={mark}>
          {children}
        </InternalLinkRenderer>
      );
    },
    link: ({ mark, children }) => {
      return <LinkRenderer link={mark}>{children}</LinkRenderer>;
    },
  },
  types: {
    block: (props) => {
      const { style = 'normal' } = props.node;
      switch (style) {
        case 'h2': {
          return <h2 className="pt-2 mb-2 text-xl">{props.children}</h2>;
        }
        case 'h3': {
          return <h3 className="pt-2 mb-2 text-lg">{props.children}</h3>;
        }
        case 'blockquote': {
          return (
            <blockquote className="mb-4 italic sm:text-lg">
              {props.children}
            </blockquote>
          );
        }
        case 'normal': {
          return (
            <p
              className={classNames('mb-4 last:mb-0', {
                'sm:text-lg leading-snug': size !== 'lg',
                'text-lg sm:text-xl md:text-2xl': size === 'lg',
              })}
            >
              {props.children}
            </p>
          );
        }
      }
      // Fall back to default handling
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return PortableText.defaultSerializers.types.block(props);
    },
    figure: (props) => <SanityFigureRenderer className="mb-4" {...props} />,
    articleFigure: ArticleFigureRenderer,
    cta: (props: {
      node: {
        title: string;
        link?: { _type: 'link'; href?: string; blank?: boolean };
        internalLink?: {
          _type: SanityPageType;
          href?: string;
          blank?: boolean;
        };
      };
    }) => {
      const { link, internalLink, title } = props.node;
      if (link && link._type === 'link') {
        return (
          <p className="mb-4 text-center">
            <LinkRenderer link={link} cta>
              {title}
            </LinkRenderer>
          </p>
        );
      }
      if (internalLink) {
        return (
          <p className="mb-4 text-center">
            <InternalLinkRenderer internalLink={{ document: internalLink }} cta>
              {title}
            </InternalLinkRenderer>
          </p>
        );
      }
      return null;
    },
  },
});

export function PortableText({
  blocks,
  className,
  size,
}: {
  blocks: [Record<string, unknown>];
  className?: string;
  size?: 'lg';
}) {
  return (
    <BlockContent
      blocks={blocks}
      serializers={serializers(size)}
      className={className}
      renderContainerOnSingleChild
    />
  );
}
