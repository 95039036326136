import classNames from 'classnames';
import { useRouter } from 'next/router';
import React, { forwardRef, useState, useEffect } from 'react';
import { useAuthUser } from '@lib/authentication';
import { AtelierLink, AtelierLinkHref, Pathname } from '../routing';
import { AnimatePresence, motion } from 'framer-motion';
import { useLockBodyScroll } from '@lib/hooks/useLockBodyScroll';
import { editionsLocationToPathname } from '@lib/editions-location-to-pathname';
import { useAuthModal } from '@components/auth/AuthModal';
import { Button } from '@components/ui/Button';
import { ProfileDropdown } from './ProfileDropdown';
import { sanitySettings } from '@lib/sanity-settings';

const navigationItems: ({
  _key: string;
  title: string;
  href: AtelierLinkHref;
  placement: 'start' | 'end';
} | null)[] = (sanitySettings.navigation || []).map((item) => {
  const { reference } = item;
  if (!reference) {
    return null;
  }
  let pathname: Pathname | undefined;
  let slug: string | undefined;
  const query: { slug?: string } = {};
  switch (reference._type) {
    case 'article': {
      pathname = '/a/[slug]';
      slug = reference.slug;
      break;
    }
    case 'card-collection': {
      pathname = '/k/[slug]';
      slug = reference.slug;
      break;
    }
    case 'post': {
      pathname = '/i/[slug]';
      slug = reference.slug;
      break;
    }
    case 'mapPage': {
      pathname = '/map';
      break;
    }
    case 'editionsPage': {
      pathname = editionsLocationToPathname(reference.location);
      break;
    }
    case 'startPage': {
      pathname = '/';
      break;
    }
    case 'termsPage': {
      pathname = '/terms';
      break;
    }
    case 'giftCardPage': {
      pathname = '/giftcard';
      break;
    }
    case 'artistsPage': {
      pathname = '/artists';
      break;
    }
  }

  if (!pathname) {
    return null;
  }

  if (slug) {
    query.slug = slug;
  }

  return {
    _key: item._key,
    title: item.title,
    placement: item.placement || 'start',
    href: {
      pathname,
      query,
    },
  };
});

const MobileNavBar = ({ open }: { open: boolean }) => {
  const { setLockBodyScroll } = useLockBodyScroll();
  useEffect(() => {
    setLockBodyScroll(open);
  }, [open]);

  const variants = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 0, x: '-100%' },
  };
  return (
    <motion.nav
      className="absolute bg-white w-full  z-20" // inset-0 z-20 top-20 bg-white"
      initial={false}
      animate={open ? 'open' : 'closed'}
      variants={variants}
    >
      <motion.div className="h-screen">
        <motion.ul className="pl-4 text-left">
          {navigationItems.map((item) => {
            if (!item) {
              return null;
            }
            return (
              <motion.li
                key={item._key}
                // variants={{
                //   open: {
                //     opacity: 1,
                //     transition: {
                //       y: { stiffness: 1000, velocity: -100 },
                //     },
                //   },
                //   closed: {
                //     opacity: 0,
                //     transition: {
                //       y: { stiffness: 1000 },
                //     },
                //   },
                // }}
                whileTap={{ scale: 0.95 }}
              >
                <AtelierLink href={item.href} prefetch={false}>
                  <NavbarAnchor>{item.title}</NavbarAnchor>
                </AtelierLink>
              </motion.li>
            );
          })}
          <motion.li whileTap={{ scale: 0.95 }}>
            <AtelierLink
              href={'https://apps.apple.com/no/app/atelier/id1219949845'}
            >
              <NavbarAnchor>{'Download iOS App'}</NavbarAnchor>
            </AtelierLink>
          </motion.li>
        </motion.ul>
      </motion.div>
    </motion.nav>
  );
};

const NavbarAnchor = forwardRef<
  HTMLAnchorElement,
  Omit<
    React.DetailedHTMLProps<
      React.AnchorHTMLAttributes<HTMLAnchorElement>,
      HTMLAnchorElement
    >,
    'className'
  >
>((props, ref) => {
  return (
    <a
      ref={ref}
      className="inline-block py-2 text-xl md:font-normal md:font-sans md:text-base md:p-2 md:hover:underline lg:p-4"
      {...props}
    />
  );
});

const navbarButtonClassName = 'md:m-2 lg:m-4';

export const Navbar = () => {
  const router = useRouter();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    const handleRouteChange = () => {
      setOpen(false);
    };
    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);
  const orderId = router.query.orderId?.toString() ?? '';
  const auth = useAuthUser();
  const navigationEnd = (
    <ul aria-hidden className="hidden -ml-4 md:flex">
      {navigationItems.map((item) => {
        if (!item || item.placement !== 'end' || orderId) {
          return null;
        }
        return (
          <li key={item.title}>
            <AtelierLink href={item.href} prefetch={false}>
              <NavbarAnchor>{item.title}</NavbarAnchor>
            </AtelierLink>
          </li>
        );
      })}
    </ul>
  );
  const { openAuthModal } = useAuthModal();
  return (
    <div
      data-testid="NavBar"
      className="relative w-full mx-auto max-w-screen-2xl"
    >
      <div className="relative z-40 flex items-center justify-center h-20 px-4 bg-white">
        <div className="flex-1">
          <ul
            data-testid="NavBar_items_start"
            aria-hidden
            className="hidden -ml-2 lg:-ml-4 md:flex"
          >
            {navigationItems.map((item) => {
              if (!item || item.placement !== 'start' || orderId) {
                return null;
              }
              return (
                <li key={item.title}>
                  <AtelierLink href={item.href}>
                    <NavbarAnchor>{item.title}</NavbarAnchor>
                  </AtelierLink>
                </li>
              );
            })}
          </ul>
          {!orderId && (
            <div className="md:hidden">
              <Button
                size="sm"
                className={classNames('flex justify-center relative')}
                onClick={() => setOpen(!open)}
                variant="outline"
              >
                <AnimatePresence initial={false} exitBeforeEnter>
                  {!open && (
                    <motion.span
                      key="menu"
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                    >
                      Meny
                    </motion.span>
                  )}
                  {open && (
                    <motion.span
                      key="close"
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                    >
                      Close
                    </motion.span>
                  )}
                </AnimatePresence>
              </Button>
            </div>
          )}
        </div>
        <h1 className={classNames('flex justify-center')} aria-label="Atelie">
          <AtelierLink href="/">
            <a className="flex justify-center w-20 sm:w-32">
              <img src="/logo/wordmark-black.svg" />
            </a>
          </AtelierLink>
        </h1>

        <div className="flex items-center justify-end flex-1 flex-shrink-0">
          {navigationEnd}
          <AnimatePresence exitBeforeEnter initial={false}>
            {!auth.user && (
              <motion.div
                key="not-logged-in"
                initial={{ opacity: 0, y: -100 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -100 }}
                className="flex-shrink-0"
              >
                <Button
                  type="button"
                  size="sm"
                  variant="primaryYellow"
                  className={navbarButtonClassName}
                  onClick={() => {
                    openAuthModal();
                  }}
                >
                  Sign in
                </Button>
              </motion.div>
            )}
            {auth.user && (
              <motion.div
                key="logged-in"
                initial={{ opacity: 0, y: -100 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -100 }}
                className="flex-shrink-0"
              >
                {/* <NotificationIcon /> */}
                <ProfileDropdown />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
      <MobileNavBar open={open} />
    </div>
  );
};
