import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Input } from '@components/ui/Input';
import { Heading } from '@components/ui/Heading';
import { Button } from '@components/ui/Button';
import {
  NewsletterSchema,
  newsletterSchema,
} from '@lib/validation-schemas/newsletter-schemas';
import { useEffect, useState } from 'react';
import { instance, URL_CREATE_ANON_CONTACT } from '../../src/constants/https';

function Form({ onSignedUp }: { onSignedUp?: () => void }) {
  const { formState, register, handleSubmit, reset } =
    useFormContext<NewsletterSchema>();
  const [email, setEmail] = useState('');
  const [customerSignedUp, setCustomerSignedUp] = useState(false);
  const { isSubmitting } = formState;

  useEffect(() => {
    async function createAnonContact() {
      await instance({})
        .put(URL_CREATE_ANON_CONTACT, {
          email,
        })
        .catch((error) => {
          console.error('Create anon newsletter contact error: ', error);
          throw new Error(error);
        });
    }

    if (email && !customerSignedUp) {
      createAnonContact();
      setCustomerSignedUp(true);
      onSignedUp && onSignedUp();
      reset();
    }
  }, [email]);

  if (customerSignedUp) {
    return (
      <Heading level="h1" size="xl" className="text-center">
        Thank you for signing up!
      </Heading>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center">
      <Heading level="h1" size="xl" className="text-center">
        Sign up for our newsletter
      </Heading>
      <p className="pt-4">
        Get curated art recommendations, updates, and alerts on new releases.
      </p>
      <form
        className="self-stretch pt-6 pl-6 pr-6 md:pl-24 md:pr-24"
        onSubmit={handleSubmit(async (data) => {
          setEmail(data.email);
        })}
      >
        <fieldset disabled={false} className="flex flex-col">
          <Input
            placeholder="Email"
            className="bg-yellow-200"
            label=""
            variant="light"
            type="text"
            id="email"
            error={formState.errors.email?.message}
            {...register('email')}
          />
        </fieldset>

        <div className="flex flex-col pt-6">
          <Button
            type="submit"
            variant="black"
            className="shadow-md self-center"
            loading={isSubmitting}
          >
            Sign me up
          </Button>
        </div>
      </form>
    </div>
  );
}

export function NewsletterSignUpForm({
  onSignedUp,
}: {
  onSignedUp?: () => void;
}) {
  const defaultValues = { email: '' };
  const methods = useForm<NewsletterSchema>({
    resolver: yupResolver(newsletterSchema),
    defaultValues,
  });
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="pt-6 pb-6">
        <FormProvider {...methods}>
          <Form onSignedUp={onSignedUp} />
        </FormProvider>
      </div>
    </div>
  );
}
