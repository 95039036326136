import React from 'react';
import Link from 'next/link';
import classNames from 'classnames';
import { LogoSymbol } from '@components/ui/Logo';
import { NewsletterSignUpFooter } from '@components/newsletter/newsletterSignUpFooter';
import { useRouter } from 'next/router';

const leftItems = [
  { href: '/a/about', children: 'About Atelie' },
  { href: '/terms', children: 'Terms' },
  {
    href: 'https://apps.apple.com/no/app/atelier/id1219949845',
    children: 'Download iOS App',
  },
];

const rightItems = [
  {
    onClick: () => {
      window.Intercom('show');
    },
    children: 'Contact us',
  },
  { href: 'https://facebook.com/atelieropenart', children: 'Facebook' },
  { href: 'http://instagram.com/atelier_open', children: 'Instagram' },
];

const anchorClassName = classNames('inline md:px-4 hover:underline');

const Navigation = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="flex flex-col md:flex-row md:flex-1 md:justify-around md:items-center">
      {children}
    </div>
  );
};

export const Footer = () => {
  const router = useRouter();
  const orderId = router.query.orderId?.toString() ?? '';
  return (
    <>
      {!orderId && <NewsletterSignUpFooter />}
      <div className="p-4 bg-yellow">
        <nav className="relative flex flex-col max-w-screen-lg mx-auto mb-8 md:items-end md:flex-row h-24">
          <Navigation>
            {leftItems.map(({ href, children }) => {
              return (
                <div key={href}>
                  {!orderId && (
                    <Link href={href}>
                      <a className={anchorClassName}>{children}</a>
                    </Link>
                  )}
                </div>
              );
            })}
          </Navigation>
          <div
            className={`absolute bottom-0 right-0 flex items-center justify-center order-last md:static md:order-none`}
          >
            <div className="w-20 md:w-24">
              <LogoSymbol />
            </div>
          </div>
          <Navigation>
            {rightItems.map(({ onClick, href, children }) => {
              if (onClick) {
                return (
                  <div key={children}>
                    {!orderId && (
                      <button
                        onClick={onClick}
                        className={classNames(
                          anchorClassName,
                          'appearance-none'
                        )}
                      >
                        {children}
                      </button>
                    )}
                  </div>
                );
              }
              if (href) {
                return (
                  <div key={href}>
                    {!orderId && (
                      <Link href={href}>
                        <a className={anchorClassName}>{children}</a>
                      </Link>
                    )}
                  </div>
                );
              }
              return null;
            })}
          </Navigation>
        </nav>
        <div className="text-xs md:text-center">
          © Atelie {new Date().getFullYear()}
        </div>
      </div>
    </>
  );
};
