import styled from "styled-components"
import { Modal } from 'semantic-ui-react'

export default styled(Modal)`
  border-radius: 0 !important;

  .modal-header {
    color: #000000 !important;
    border-radius: 0 !important;
    font-weight: normal !important;
    font-size: 20px !important;
    border: 0 !important;
  }

  .button {
    border-radius: 0 !important;
  }

  .actions {
    border: 0 !important;
    color: #000000 !important;
  }

  .content {
    border: 0 !important;
  }

`