import classNames from 'classnames';
import React from 'react';
import { Footer } from './Footer';
import { Navbar } from './Navbar';
import CookieNotifier from './CookieNotifier';
import InviteModal from 'layout/Navbar/containers/InviteModal';
import { useAuthUser, useUserDoc } from '@lib/authentication';
import { useRouter } from 'next/router';
import { StripeIssuesBanner } from '../../stripe/StripeIssuesBanner';
import { NextSeo } from 'next-seo';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface LayoutOptions {}

interface OpenGraph {
  description?: string;
  image_height?: number;
  image_width?: number;
  title?: string;
  image?: string;
}

export interface LayoutProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  openGraph?: OpenGraph;
  hideChrome?: boolean;
}

export const Layout = ({
  title,
  className,
  openGraph,
  hideChrome,
  ...props
}: LayoutProps) => {
  const router = useRouter();
  const { user } = useAuthUser();
  const { userDoc } = useUserDoc();
  return (
    <>
      <div className="flex flex-col min-h-screen">
        <NextSeo
          title={title}
          description={openGraph ? openGraph.description : undefined}
          openGraph={
            openGraph
              ? {
                  title: openGraph.title,
                  images: openGraph.image
                    ? [
                        {
                          url: openGraph.image,
                          width: openGraph.image_width,
                          height: openGraph.image_height,
                        },
                      ]
                    : undefined,
                }
              : undefined
          }
        />
        {userDoc?.isArtist && <StripeIssuesBanner />}
        {router.isPreview && (
          <div className="fixed z-50 px-3 py-2 text-xs text-black whitespace-pre -translate-x-1/2 bg-yellow-300 rounded-full shadow-lg sm:text-sm top-3 left-1/2">
            You are previewing content from Sanity.{' '}
            <a
              href="/api/exit-preview"
              className="underline transition-colors duration-200 hover:text-cyan"
            >
              Exit preview
            </a>
          </div>
        )}
        {user && <InviteModal />}
        {!hideChrome && <Navbar />}
        <main
          className={classNames('flex-1', className, { 'my-4': hideChrome })}
          {...props}
        />
        <CookieNotifier />
      </div>
      {!hideChrome && <Footer />}
    </>
  );
};

export const getLayout = (page: React.ReactNode, options?: LayoutOptions) => (
  <Layout {...options}>{page}</Layout>
);
