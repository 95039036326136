// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore this file is gitignored, and generated on Next.js build
import settingsJson from '@lib/sanity-settings.json';
import type { SanitySettings } from './sanity.server';

if (!settingsJson) {
  throw new Error('Sanity settings not found');
}

export const sanitySettings = (settingsJson as unknown) as SanitySettings;
