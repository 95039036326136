import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';
import { gql, useMutation } from '@apollo/client';
import {
  CreateStripeAccountMutation,
  CreateStripeAccountMutationVariables,
} from '@generated/codegen';
import { useToasts } from '@components/toasts';
import { useCurrentUser } from '@lib/data/current-user';

const CREATE_STRIPE_ACCOUNT = gql`
  mutation createStripeAccount {
    createStripeAccount
  }
`;

export function useCreateStripeAccount() {
  const toasts = useToasts();
  const { currentUser } = useCurrentUser();
  const [redirecting, setRedirecting] = useState(false);
  const [createStripeAccount, { loading }] = useMutation<
    CreateStripeAccountMutation,
    CreateStripeAccountMutationVariables
  >(CREATE_STRIPE_ACCOUNT, {
    onError: () => {
      setRedirecting(false);
      toasts.addToast({ text: 'Could not connect to Stripe', type: 'error' });
    },
    onCompleted: (data) => {
      if (data) {
        setRedirecting(true);
        window.location.href = data.createStripeAccount;
      }
    },
  });
  const router = useRouter();

  const isArtist = currentUser?.isArtist;

  useEffect(() => {
    if (isArtist && router.query.stripe_refresh_url === '1') {
      createStripeAccount();
    }
  }, [router.query.stripe_refresh_url, isArtist]);

  return {
    loading: loading || redirecting,
    createStripeAccount,
  };
}
