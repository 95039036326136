import styled from 'styled-components'
import { Button, Image } from 'semantic-ui-react'

export const AcceptButton = styled(Button)`
width: 150px !important;
height: 45px !important;
font-size: 1rem !important;
color: #16374A !important;
font-weight: 400 !important;
border: 1px solid #16374A9a !important;
border-radius:0 !important;
text-shadow: 1px 1px #fff;  
padding-top:17px !important;
float: right !important;
background: #ffffff9a !important;
margin-bottom: 15px !important;
`

export const LaterButton = styled(Button)`
width: 150px !important;
height: 45px !important;
font-size: 1rem !important;
color: #000000 !important;
font-weight: 400 !important;
border-radius:0 !important;
text-shadow: 1px 1px #fff;  
background: #ffffff9a !important;
padding-top: 14px !important;
float: left !important;
margin-right: 10px !important;
text-decoration: underline !important;
text-align: left !important;
margin-left: -12px !important;
margin-top:8px !important;
`

export const AcceptImage = styled(Image)`
float: left !important;
margin-top: -10px !important;
`