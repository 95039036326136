import React from 'react';

// TODO: Deprecated
export function LogoFull() {
  return AtelieLogo;
}

const AtelieLogo = (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 787.5 245"
  >
    <path
      d="M122.89,14.15h-7.16L70.95,146.47H19.39v10.27h48.08l-20.86,61.64H19.52v10.27h67.56v-10.27H57.51l20.86-61.64
h62.89l21.79,61.64H134.1v10.27h24.6l23.66-0.31l20.86,0.31h13.39v-10.27h-21.17l-21.9-61.64h43.07v-10.27h-46.72L122.89,14.15z
M81.79,146.47l27.09-80.01h0.31l28.33,80.01H81.79z"
    />
    <path
      d="M315.61,190.06c0,19.62-11.83,32.38-27.09,32.38c-13.39,0-19.3-7.16-19.3-26.15V99.46h42.03V89.19h-42.03V27.54
h-10.27c-4.98,48.88-15.26,61.65-41.41,61.65h-7.16v10.27h32.69v93.4c0,10.27,0,18.68,4.67,25.22
c6.85,10.27,20.86,15.57,34.87,15.57c20.24,0,43.28-10.27,43.28-45.14v-29.58h-10.27V190.06z"
    />
    <path
      d="M469.39,156.75l2.18-2.49c-2.18-38.61-23.66-70.05-60.09-70.05c-34.56,0-66.63,35.81-66.63,74.72
c0,39.54,32.69,74.72,67.87,74.72c33.93,0,56.04-24.91,57.29-58.53h-10.28c-1.24,28.02-18.99,48.26-47.01,48.26
c-22.11,0-35.8-14.95-35.8-43.28v-23.35H469.39z M376.92,142.43c0-23.97,2.8-33,12.14-40.79c5.6-4.67,14.01-7.16,20.86-7.16
c18.06,0,27.09,13.7,27.09,37.98v14.01h-60.09V142.43z"
    />
    <polygon
      points="528.01,12.91 526.45,11.35 512.44,16.33 477.88,16.33 477.88,26.61 501.86,26.61 501.86,218.39 
477.88,218.39 477.88,228.67 497.81,228.67 514.93,228.36 532.06,228.67 551.98,228.67 551.98,218.39 528.01,218.39 "
    />
    <path
      d="M599.58,57.73c10.27,0,18.99-8.72,18.99-19.3c0-10.59-8.72-19.3-18.99-19.3c-10.59,0-19.3,8.72-19.3,19.3
C580.27,49.01,588.99,57.73,599.58,57.73"
    />
    <polygon
      points="613.59,85.76 612.03,84.2 598.02,89.19 563.46,89.19 563.46,99.46 587.44,99.46 587.44,218.39 
563.46,218.39 563.46,228.67 583.39,228.67 600.51,228.36 617.64,228.67 637.56,228.67 637.56,218.39 613.59,218.39 "
    />
    <path
      d="M765.93,156.76l2.18-2.49c-2.18-38.61-23.66-70.07-60.09-70.07c-34.56,0-66.63,35.81-66.63,74.72
c0,39.54,32.69,74.72,67.87,74.72c33.93,0,56.04-24.91,57.29-58.53h-10.27c-1.24,28.02-18.99,48.26-47.01,48.26
c-22.11,0-35.81-14.95-35.81-43.28v-23.34H765.93z M673.46,142.43c0-23.97,2.8-33,12.14-40.79c5.6-4.67,14.01-7.16,20.86-7.16
c18.06,0,27.09,13.7,27.09,37.98v14.01h-60.09V142.43z"
    />
  </svg>
);

export function LogoSymbol() {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 474 502"
      className="w-full h-full fill-current"
    >
      <g>
        <path
          d="M247.7,24.8h-14.9l-93.4,275.9H31.9v21.4h100.3L88.7,450.7H32.2v21.4h140.9v-21.4h-61.7l43.5-128.5H286l45.4,128.5h-60.4
		v21.4h51.3l49.3-0.6l43.5,0.6h27.9v-21.4H399l-45.7-128.5h89.8v-21.4h-97.4L247.7,24.8z M162,300.7l56.5-166.8h0.6l59.1,166.8H162z
"
        />
      </g>
    </svg>
  );
}
