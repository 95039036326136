import classNames from 'classnames';
import { useRouter } from 'next/router';
import React from 'react';
import {
  useAdminRoleCheck,
  useAuthUser,
  useUserDoc,
} from '@lib/authentication';
import { CurrentUserAvatar } from '@components/UserAvatar';
import { AtelierLink, AtelierLinkHref } from '../routing';
import {
  LogOut,
  Settings,
  User,
  Icon,
  Headphones,
  MessageSquare,
} from 'react-feather';
import { Menu } from '@headlessui/react';
import firebase from 'firebase/app';
// import { NotificationIcon } from '@components/notifications';
import { AnimatePresence, motion } from 'framer-motion';
import { useUnreadConversations } from '@components/my-page/Conversations/hooks';

// workaround for headless-ui: https://github.com/tailwindlabs/headlessui/issues/20#issuecomment-701482746
function MenuAtelierLink({
  href,
  ...props
}: { href: AtelierLinkHref } & Omit<
  React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  >,
  'href'
>) {
  return (
    <AtelierLink href={href}>
      <a {...props} />
    </AtelierLink>
  );
}

function PingAnimation({
  className,
  ...props
}: React.HTMLAttributes<HTMLSpanElement>) {
  return (
    <span
      className={classNames('flex w-3 h-3', className)}
      aria-label="Du har uleste meldinger"
      {...props}
    >
      <span className="absolute inline-flex w-full h-full bg-red-400 rounded-full opacity-75 animate-ping"></span>
      <span className="relative inline-flex w-3 h-3 bg-red-500 rounded-full"></span>
    </span>
  );
}

export function ProfileDropdown() {
  const [unreadConversations] = useUnreadConversations();
  const { userDoc } = useUserDoc();
  const router = useRouter();
  const { user } = useAuthUser();
  const isAdmin = useAdminRoleCheck();
  if (!user) {
    return null;
  }
  const haveUnreadMessages = unreadConversations.length > 0;
  const userNavigation: {
    name: string;
    href: AtelierLinkHref;
    Icon: Icon;
    showPing?: boolean;
  }[] = [
    { name: 'Profile', href: '/my-page', Icon: User },
    {
      name: 'Messages',
      href: '/my-page/messages',
      Icon: MessageSquare,
      showPing: haveUnreadMessages,
    },
  ];
  if (userDoc?.isArtist) {
    userNavigation.push({
      name: 'My Studio',
      href: '/my-studio',
      Icon: Settings,
    });
  }
  if (isAdmin) {
    userNavigation.push({
      name: 'Admin',
      href: '/admin',
      Icon: Headphones,
    });
  }
  return (
    <Menu as="div" className="relative flex-shrink-0 ml-2">
      {({ open }) => (
        <>
          <div>
            <Menu.Button
              className="relative flex items-center text-sm rounded-full"
              aria-label="Open menu"
            >
              {haveUnreadMessages && (
                <PingAnimation className="absolute top-0 left-0" />
              )}
              <CurrentUserAvatar />
            </Menu.Button>
          </div>
          <AnimatePresence>
            {open && (
              <Menu.Items
                static
                as={motion.div}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className="absolute right-0 z-10 w-48 py-1 mt-2 origin-top-right bg-white divide-y divide-gray-100 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                data-testid="ProfileDropdownMenu"
              >
                {user.email && (
                  <div className="px-4 py-3">
                    <p className="text-xs">Signed in as</p>
                    <p className="text-sm font-medium text-gray-900 truncate">
                      {user.email}
                    </p>
                  </div>
                )}
                <div className="py-1">
                  {userNavigation.map((item) => (
                    <Menu.Item key={item.name}>
                      {({ active }) => (
                        <MenuAtelierLink
                          href={item.href}
                          className={classNames(
                            active ? 'bg-gray-100' : '',
                            'group flex items-center px-3 py-2 text-sm relative'
                          )}
                        >
                          <item.Icon className="w-5 h-5 mr-2 text-gray-400 group-hover:text-gray-500" />
                          {item.name}
                          {item.showPing && (
                            <PingAnimation className="absolute left-1 top-1" />
                          )}
                        </MenuAtelierLink>
                      )}
                    </Menu.Item>
                  ))}
                </div>

                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={classNames(
                        active ? 'bg-gray-100' : '',
                        'w-full group flex items-center px-4 py-2 text-sm'
                      )}
                      onClick={() => {
                        firebase
                          .auth()
                          .signOut()
                          .then(() => router.push('/'));
                      }}
                    >
                      <LogOut
                        className="w-5 h-5 mr-2 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                      Sign out
                    </button>
                  )}
                </Menu.Item>
              </Menu.Items>
            )}
          </AnimatePresence>
        </>
      )}
    </Menu>
  );
}
