import React, { Component } from 'react';
import { Modal, Header } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { fetchInvite, setPrompted } from 'actions/invite';
import { instance, URL_ACCEPT_INVITATION } from 'constants/https';
import firebase from 'firebase/app';
import { AcceptButton, LaterButton, AcceptImage } from './style';
import StyledModal from 'styled/Modal/Modal';
import Accept from './accept.svg';

const { auth } = firebase;

class InviteModal extends Component {
  state = {
    open: true,
  };

  UNSAFE_componentWillMount() {
    this.props.fetchInvite();
  }

  acceptInvite = (key) => {
    return auth()
      .currentUser.getIdToken()
      .then((token) => {
        return instance({ token }).post(URL_ACCEPT_INVITATION, { key });
      });
  };

  handleClose = () => {
    const { setPrompted } = this.props;

    setPrompted();
    this.setState({ open: false });
  };

  handleConfirm = () => {
    const { invite, setPrompted } = this.props;

    this.acceptInvite(invite.key)
      .then(() => {
        console.log('invite accepted');
      })
      .catch((error) => {
        console.log('error', error.response.data);
      });

    setPrompted();
    this.setState({ open: false });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.invite && nextProps.invite.prompted === false) {
      this.setState({
        open: true,
      });
    }
  }

  render() {
    const { open } = this.state;
    const { invite } = this.props;

    if (!invite.hasInvite) {
      return <div />;
    }

    return (
      <StyledModal
        open={open && !invite.prompted && !invite.accepted}
        closeOnDimmerClick={false}
        onClose={this.handleClose}
        size="small"
      >
        <Header content="Artist invite" className="modal-header"></Header>
        <Modal.Content>
          <h3>Hi !</h3>
          <p>{`You have received an artist invitation from ${invite.from.name}.`}</p>

          <p>
            With an artist invitation, you will be granted an artist profile on
            this platform, allowing you to sell art and reach a wider audience.
          </p>
        </Modal.Content>
        <Modal.Actions>
          <AcceptButton color="green" onClick={this.handleConfirm} size="large">
            <AcceptImage src={Accept} />
            Accept
          </AcceptButton>
          <LaterButton onClick={this.handleClose} size="large">
            Decide later
          </LaterButton>
        </Modal.Actions>
      </StyledModal>
    );
  }
}

function mapStateToProps(state) {
  return { invite: state.invite };
}

export default connect(mapStateToProps, { fetchInvite, setPrompted })(
  InviteModal
);
