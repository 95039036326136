import { usePrivateData } from '@lib/authentication';
import {
  conversationCollection,
  conversationMessagesCollection,
  conversationParticipantsCollection,
} from '@lib/client/controllers/conversations';
import firebase from '@lib/firebase';
import {
  ConversationParticipantDocSchema,
  conversationParticipantDocSchema,
} from '@shared/client/models/conversation';
import { useCollectionData } from 'react-firebase-hooks/firestore';

export function useConversationParticipants({
  conversationId,
}: {
  conversationId: string;
}) {
  return useCollectionData<ConversationParticipantDocSchema>(
    conversationCollection()
      .doc(conversationId)
      .collection('participants')
      .limit(2)
  );
}

export async function readMessage({
  messageId,
  uid,
  conversationId,
}: {
  messageId: string;
  uid: string;
  conversationId: string;
}) {
  const messageDoc = await conversationMessagesCollection(conversationId)
    .doc(messageId)
    .get();
  if (!messageDoc.exists) {
    return;
  }
  const ref = conversationParticipantsCollection(conversationId).doc(uid);
  const participantDoc = await ref.get();
  if (!participantDoc.exists) {
    return;
  }
  const participant = conversationParticipantDocSchema.parse(
    participantDoc.data()
  );
  if (
    participant.lastReadEvent &&
    participant.lastReadEvent.messageId === messageDoc.id
  ) {
    return;
  }
  const updated = conversationParticipantDocSchema.partial().parse({
    lastReadEvent: {
      messageId: messageDoc.id,
      timestamp: firebase.firestore.Timestamp.now(),
    },
  });
  await ref.update(updated);
}

export function useUnreadConversations(): [string[], boolean] {
  const [privateData, loading] = usePrivateData();
  const unreadConversations =
    privateData && privateData.unreadConversations
      ? privateData.unreadConversations
      : [];
  return [unreadConversations, loading];
}
