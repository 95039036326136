import { z } from 'zod';
import {
  FirebaseFirestoreDocumentReferenceSchema,
  FirebaseFirestoreTimestampSchema,
} from './helpers';

export const conversationDocSchema = z.object({
  createdAt: FirebaseFirestoreTimestampSchema,
  createdBy: z.string(),
  lastMessageReceived: FirebaseFirestoreTimestampSchema.optional(),
  participants: z.record(z.boolean()),
});

export const conversationMessageDocSchema = z.object({
  createdAt: FirebaseFirestoreTimestampSchema,
  lastUpdated: FirebaseFirestoreTimestampSchema.optional(),
  content: z.string(),
  senderId: z.string(),
  senderRef: FirebaseFirestoreDocumentReferenceSchema,
});

export const conversationParticipantDocSchema = z.object({
  userId: z.string(),
  userRef: FirebaseFirestoreDocumentReferenceSchema,
  hasUnread: z.boolean().optional(),
  lastReadEvent: z
    .object({
      messageId: z.string(),
      timestamp: FirebaseFirestoreTimestampSchema,
    })
    .optional(),
});

export type ConversationDocSchema = z.infer<typeof conversationDocSchema>;
export type ConversationParticipantDocSchema = z.infer<
  typeof conversationParticipantDocSchema
>;
export type ConversationMessageDocSchema = z.infer<
  typeof conversationMessageDocSchema
>;
