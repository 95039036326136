import { SanityImageSource } from '@sanity/image-url/lib/types/types';
import {
  createClient,
  createImageUrlBuilder,
  createPreviewSubscriptionHook,
} from 'next-sanity';
import { SANITY_CONFIG } from './constants';

const builder = createImageUrlBuilder(SANITY_CONFIG);

export function urlForImage(source: SanityImageSource) {
  return builder.image(source).auto('format').fit('max');
}

export const usePreviewSubscription = createPreviewSubscriptionHook(
  SANITY_CONFIG
);

export const sanityClient = createClient(SANITY_CONFIG);
