import { Anchor } from '@components/ui/Anchor';
import { STRIPE_DASHBOARD_URL } from '@lib/constants';
import { useCurrentUser } from '@lib/data/current-user';
import React from 'react';
import { useCreateStripeAccount } from './useCreateStripeAccount';

export function StripeIssuesBanner() {
  const { currentUser } = useCurrentUser();
  const { loading, createStripeAccount } = useCreateStripeAccount();
  if (!currentUser || !currentUser.isArtist || !currentUser.studio) {
    return null;
  }
  const { stripeAccount } = currentUser.studio;
  let component;

  if (!stripeAccount) {
    component = (
      <>
        In order to sell artworks, you need a Stripe account, so that you can
        get paid.{' '}
        <button
          className="underline hover:no-underline"
          disabled={loading}
          onClick={() => {
            createStripeAccount();
          }}
        >
          Connect to Stripe
        </button>
      </>
    );
  } else if (!stripeAccount.details_submitted) {
    component = (
      <>
        {' '}
        You have not completed your registration with Stripe .{' '}
        <button
          className="underline hover:no-underline"
          disabled={loading}
          onClick={() => {
            createStripeAccount();
          }}
        >
          Complete registration
        </button>
      </>
    );
  } else if (!stripeAccount.charges_enabled) {
    component = (
      <>
        {' '}
        Your artworks are not for sale due to a problem with your Stripe
        account.{' '}
        <Anchor external href={STRIPE_DASHBOARD_URL} target="blank" underline>
          Solve the problem at Stripe
        </Anchor>
      </>
    );
  }
  if (!component) {
    return null;
  }
  return (
    <div className="bg-red-600 isolate z-40">
      <div className="max-w-5xl mx-auto text-center py-3 px-3 sm:px-6 lg:px-8 text-white">
        {component}
      </div>
    </div>
  );
}
